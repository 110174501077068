
<template>
  <div class="reports">
    <h1 v-if="user_level > 1">
      Processos Inativos
      <span class="h1-underline"></span>
    </h1>
    <h1 v-else>
      As Minhas Leads
      <span class="h1-underline"></span>
    </h1>

    <!-- FILTRO: -->
    <FiltersLeads :estados="estados" @change-estado="updateEstado" />

    <!-- TABELA: -->
    <v-card>
      <v-card-title class>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Pesquisar"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-data-table
        :headers="user_level > 1 ? headersAdmin : headersAgent"
        :items="filteredLeads"
        :search="search"
        :loading="loader"
        loading-text="A carregar..."
      >
        <!-- CHECKBOX -->
        <template v-slot:[`item.ativar`]="{ item }">
          <div class="d-flex justify-center align-center">
            <v-checkbox
              class="m-0"
              v-model="checkbox"
              hide-details
              multiple
              color="rgb(196, 194, 44)"
              :value="item"
            ></v-checkbox>
          </div>
        </template>

        <template v-slot:[`item.editar`]="{ item }">
          <LeadInsertData
            @snackbar="snackbarController"
            :lead="item"
            @update-made="collectLeads"
          />
        </template>

        <!-- VIEW DETAILS -->
        <template v-slot:[`item.detalhes`]="{ item }">
          <LeadDetail :lead="item" />
        </template>
      </v-data-table>

      <v-row class="d-flex justify-end align-end mr-2">
        <v-btn
          v-if="checkbox.length > 0"
          @click="enableLeads()"
          class="ma-2 white--text"
          color="rgb(196, 194, 44)"
          bottom
        >
          Ativar Leads
        </v-btn>
      </v-row>
    </v-card>
    <v-snackbar
      v-model="snackbar"
      :color="snackbarColor"
      :timeout="5000"
      bottom
    >
      <p class="font-weight-black">
        {{ snackbarMessage }}
      </p>
    </v-snackbar>
  </div>
</template>

<script>
import { json2excel } from "js2excel";
import FiltersLeads from "@/components/Filters_Leads";
import LeadDetail from "@/components/Lead_Detail";
import LeadInsertData from "@/components/Lead_Insert_Data";

export default {
  components: {
    FiltersLeads,
    LeadDetail,
    LeadInsertData
  },
  name: "Leads-inacgtives",
  data() {
    return {
      snackbarMessage: "",
      snackbarColor: "",
      snackbar: false,
      search: "",
      username: "",
      checkbox: false,
      headersAgent: [
        {
          text: "ID",
          align: "left",
          value: "lead_process_id",
        },
        // { text: 'Data', align: 'center', value: 'dt_created', sortable:true },
        { text: "Nome", align: "center", value: "nome", sortable: true },
        { text: "Email", align: "center", value: "email", sortable: true },
        {
          text: "Telefone",
          align: "center",
          value: "telefone",
          sortable: true,
        },
        { text: "Montante", align: "center", value: "aux_1", sortable: true },
        // { text: 'Prazo', align: 'center', value: 'aux_2', sortable:true },
        {
          text: "Último Contacto",
          align: "center",
          value: "last_interation",
          sortable: true,
        },
        {
          text: "Agendamento",
          align: "center",
          value: "callback_date",
          sortable: true,
        },
        {
          text: "Estado",
          align: "center",
          value: "current_step",
          sortable: true,
        },
        {
          text: "Detalhes",
          align: "center",
          value: "detalhes",
          sortable: true,
        },
        { text: "Editar", align: "center", value: "editar", sortable: false },
        { text: "Ativar", align: "center", value: "ativar", sortable: false },
      ],

      headersAdmin: [
        {
          text: "ID",
          align: "left",
          value: "lead_process_id",
        },
        {
          text: "Mediador",
          align: "center",
          value: "username",
          sortable: true,
        },
        { text: "Nome", align: "center", value: "nome", sortable: true },
        { text: "Email", align: "center", value: "email", sortable: true },
        {
          text: "Telefone",
          align: "center",
          value: "telefone",
          sortable: true,
        },
        { text: "Montante", align: "center", value: "aux_1", sortable: true },
        // { text: 'Prazo', align: 'center', value: 'aux_2', sortable:true },
        {
          text: "Último Contacto",
          align: "center",
          value: "last_interation",
          sortable: true,
        },
        {
          text: "Agendamento",
          align: "center",
          value: "callback_date",
          sortable: true,
        },
        {
          text: "Estado",
          align: "center",
          value: "current_step",
          sortable: true,
        },
        {
          text: "Detalhes",
          align: "center",
          value: "detalhes",
          sortable: true,
        },
        { text: "Editar", align: "center", value: "editar", sortable: false },
        { text: "Ativar", align: "center", value: "ativar", sortable: false },
      ],
      items: [],
      loader: true,
      loading: false,
      loading2: false,
      loading3: false,
      loading4: false,
      loading5: false,

      user_level: null,
      estados: [],
      estado: "All",

      moment: require("moment"),
    };
  },
  computed: {
    filteredLeads() {
      const dados = this.$store.state.leads_disabled;

      const d = Object.keys(dados).map((i) => dados[i]);

      if (this.estado == "All") {
        return d;
      } else {
        let map = d.filter((e) => {
          return e.current_step == this.estado;
        });

        return map;
      }
    },
  },
  methods: {
    snackbarController(res) {
      this.snackbar = true;

      if (res.resultado == "OK") {
        this.snackbarMessage = "As alterações foram guardadas com sucesso!";
        this.snackbarColor = "success";
      } else {
        this.snackbarMessage = res.details;
        this.snackbarColor = "error";
      }
    },
    async enableLeads() {
      //Leads to Enable:
      let leadsActivated = this.checkbox.map(function (e) {
        const getProcessId = (cond) => {
          if (cond == "seguro_de_vida") return "35";
          else if (cond == "credito_habitacao") return "12";
          else return "3";
        };

        return {
          lead_process_id: e.lead_process_id,
          username: e.username,
          process_step_id: getProcessId(e.produto),
        };
      });

      //Zerar a tabela:
      await this.$store.commit("SET_GLOBAL_VARIABLE", {
        value: [],
        var: "leads_disabled",
      });

      this.loader = true;

      //Ativar Lead:
      for (let i in leadsActivated) {
        await this.$store.dispatch("set_activate_lead", leadsActivated[i]);
      }

      await this.$store.dispatch("collect_leads_disabled");

      //Unselected checkbox
      this.checkbox = [];
      this.loader = false;
    },

    export_excel: async function (data) {
      try {
        json2excel({
          data,
          name: `all-lead-processes`,
          formateDate: "yyyy-mm-dd",
        });
      } catch (e) {
        alert("export error: " + e);
      }
    },
    updateEstado: async function (val) {
      this.estado = val;
    },
    collectLeads: async function () {
      //Função responsável pela chamada das Leads Inativas:

      if (
        this.$store.state.leads_disabled.length == 0 ||
        this.$store.state.leads_disabled.includes("Error")
      ) {
        await this.$store.dispatch("collect_leads_disabled");
      }

      if (this.$store.state.consultores.length == 0) {
        await this.$store.dispatch("collect_consultores");
      }

      const dados = this.$store.state.leads_disabled;

      const d = Object.keys(dados).map((i) => dados[i]);

      //Set all table items:

      this.estados = [...new Set(d.map((item) => item.current_step))];
      this.estados.push("All");
      this.estados = this.estados.sort();

      this.loader = false;
    },
  },
  mounted: function () {
    let isLogged = window.sessionStorage.getItem("user_id");
    const result = isLogged == null ? false : true;

    if (!result) {
      this.$router.push("login");
    } else {
      this.username = window.sessionStorage.getItem("username");
      this.user_level = window.sessionStorage.getItem("user_level");
    }

    this.collectLeads();
  },
};
</script>

<style lang="scss" scoped>
#download {
  z-index: 999;
}
h1 {
  margin-left: 296px;
  color: black;
  font-size: 20px;
  text-transform: uppercase;
  margin-top: 50px;
  position: relative;
  display: block;
}
.h1-underline {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 3px;
  background-color: black;
  margin-bottom: -6px;
}
.v-card {
  margin: 10px 40px 50px 296px;
  padding: 40px;
  padding-top: 10px;
}
.v-card__title {
  margin-bottom: 30px;
}

.v-btn i {
  font-size: 30px !important;
}
</style>>
   